import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mx-auto" }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.loading ? 'Loading...' : _ctx.label), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}