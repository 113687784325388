import {
  addressToLink,
  numberToLocaleString,
  shortHexString,
  formatBigIntToDecimal,
} from './format';
const filters = {
  addressToLink,
  numberToLocaleString,
  shortHexString,
  formatBigIntToDecimal,
};
export default filters;
