import { IGlobalModuleState, IRootStoreState } from '@/types';
import { Module } from 'vuex';
import axios from 'axios';

export const globalModule: Module<IGlobalModuleState, IRootStoreState> = {
  namespaced: true,
  state: {
    blockNumber: 0,
    usdPrices: new Map(),
    isLoadingUsdPrices: false,
    showNavigation: null,
  },
  getters: {},
  mutations: {
    setBlockNumber(state, data: number) {
      state.blockNumber = data;
    },
    setUsdPrices(state, data: Map<number, number>) {
      state.usdPrices = data;
    },
    setIsLoadingUsdPrices(state, data: boolean) {
      state.isLoadingUsdPrices = data;
    },
    setNavigationState(state, data: boolean) {
      state.showNavigation = data;
    },
  },
  actions: {
    updateBlockNumber({ commit }, payload: number) {
      commit('setBlockNumber', payload);
    },
    setNavigationDrawer({ commit }, payload) {
      commit('setNavigationState', payload);
    },
    fetchUsdPrices({ commit }) {
      commit('setIsLoadingUsdPrices', true);
      axios
        .get<{ prices: Array<Array<number>> }>(
          'https://api.coingecko.com/api/v3/coins/king/market_chart?vs_currency=usd&days=30&interval=daily'
        )
        .then((result) => {
          const prices = new Map();
          result.data?.prices.forEach((dayData) => {
            const [timestamp, price] = dayData;
            prices.set(timestamp, price);
          });

          commit('setUsdPrices', prices);
        })
        .finally(() => commit('setIsLoadingUsdPrices', false));
    },
  },
};
