
import { numberToLocaleString } from '@/utils/format';
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'HeaderBar',
  data() {
    return { searchString: '' };
  },
  computed: {
    ...mapState('settings', {
      theme: 'theme',
    }),
    ...mapState('global', {
      showNavigation: 'showNavigation',
      blockNumber: 'blockNumber',
    }),
    displayBlockNumber(): string {
      return numberToLocaleString(this.blockNumber ?? 0);
    },
  },
  methods: {
    ...mapActions('global', ['setNavigationDrawer']),
    ...mapActions('settings', ['actionToggleTheme']),
    isValidAddress(address: string): boolean {
      return /^0x[a-fA-F0-9]{40}$/.test(address);
    },
    onSearch() {
      const searchString = this.searchString;
      if (this.isValidAddress(searchString)) {
        this.searchString = '';
        this.$router.push(`/user/${searchString}`);
      }
    },
  },
});
