
import { defineComponent } from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import { numberToLocaleString } from '@/utils/format';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'StakingChart',
  props: {
    stakingsData: {
      type: Array<number>,
      required: true,
    },
    daysData: {
      type: Array<number>,
      required: true,
    },
  },
  components: {
    VueApexCharts,
  },
  computed: {
    ...mapState('settings', {
      theme: 'theme',
    }),
    currentStakedAmount(): number {
      return this.stakingsData[0] ?? 0;
    },
    chartOptions() {
      return {
        chart: {
          type: 'area',
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        colors: ['#7367f0', '#ff9f43', '#28c76f'],
        tooltip: {
          x: {
            format: 'dd MMM yyyy',
          },
          y: {
            formatter: function (value: number) {
              return `${numberToLocaleString(value, 2)} KING`;
            },
          },
        },
        title: {
          text: `Staked Tokens - ${numberToLocaleString(
            this.currentStakedAmount,
            2
          )} KING`,
          align: 'left',
          style: { color: this.theme === 'dark' ? '#fff' : '#000' },
        },
        labels: this.daysData,
        xaxis: {
          type: 'datetime',
          labels: {
            style: { colors: this.theme === 'dark' ? '#fff' : '#000' },
          },
        },
        yaxis: {
          opposite: true,
          labels: {
            formatter: function (value: number) {
              return `${numberToLocaleString(value, 2)} KING`;
            },
            style: { colors: this.theme === 'dark' ? '#fff' : '#000' },
          },
        },
        legend: {
          horizontalAlign: 'left',
        },
      };
    },
    series(): Array<{ name: string; data: Array<number> }> {
      return [
        {
          name: 'Staked Tokens',
          data: this.stakingsData,
        },
      ];
    },
  },
});
