
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'StatsCard',
  props: {
    label: {
      type: [String, Number],
      require: true,
    },
    loading: {
      type: Boolean,
    },
    title: {
      type: String,
      require: true,
    },
  },
});
