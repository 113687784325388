import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Dashboard from '../views/Dashboard.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/holders',
    name: 'Holders',
    component: () =>
      import(/* webpackChunkName: "holders" */ '../views/Holders.vue'),
  },
  {
    path: '/kingpassholders',
    name: 'KingPass Holders',
    component: () =>
      import(/* webpackChunkName: "holders" */ '../views/KingPassHolders.vue'),
  },
  {
    path: '/user/:userId(0x[a-fA-F0-9]{40})',
    name: 'User',
    component: () => import(/* webpackChunkName: "user" */ '../views/User.vue'),
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
