import { ISettingsModuleState, IRootStoreState } from '@/types';
import { Module } from 'vuex';

export const settingsModule: Module<ISettingsModuleState, IRootStoreState> = {
  namespaced: true,
  state: {
    theme: 'dark',
  },
  getters: {},
  mutations: {
    mutateTheme(state, data: string) {
      state.theme = data;
    },
  },
  actions: {
    actionToggleTheme({ commit, state }) {
      const newThemeState = state.theme === 'dark' ? 'light' : 'dark';
      commit('mutateTheme', newThemeState);
    },
  },
};
