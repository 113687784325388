import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "2",
            class: "d-flex justify-start align-center"
          }, {
            default: _withCtx(() => [
              (_ctx.$vuetify.display.mobile)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    icon: "mdi-menu",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setNavigationDrawer(!_ctx.showNavigation)))
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "8",
            class: "d-flex justify-center align-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    density: "compact",
                    variant: "solo",
                    label: "Search address",
                    placeholder: "0xe3f7d290195ebc009bae5dd972ccf992cdf07717",
                    "append-inner-icon": "mdi-magnify",
                    "single-line": "",
                    "hide-details": "",
                    clearable: "",
                    modelValue: _ctx.searchString,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchString) = $event)),
                    onKeyup: _withKeys(_ctx.onSearch, ["enter"]),
                    "onClick:appendInner": _ctx.onSearch
                  }, null, 8, ["modelValue", "onKeyup", "onClick:appendInner"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "2",
            class: "d-flex justify-end align-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                icon: _ctx.theme === 'light' ? 'mdi-weather-sunny' : 'mdi-weather-night',
                onClick: _ctx.actionToggleTheme
              }, null, 8, ["icon", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}