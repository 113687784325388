import { IRootStoreState } from '@/types/store';
import { settingsModule, globalModule } from '@/store/modules';
import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

export default createStore({
  modules: {
    settings: settingsModule,
    global: globalModule,
  },
  state: {} as IRootStoreState,
  plugins: [
    new VuexPersistence({
      key: 'kingstats_v0.0.1',
      reducer: (state: IRootStoreState) => ({
        settings: state.settings,
      }),
    }).plugin,
  ],
});
