// @ts-nocheck
import { GraphQLResolveInfo, SelectionSetNode, FieldNode, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { gql } from '@graphql-mesh/utils';

import type { GetMeshOptions } from '@graphql-mesh/runtime';
import type { YamlConfig } from '@graphql-mesh/types';
import { PubSub } from '@graphql-mesh/utils';
import { DefaultLogger } from '@graphql-mesh/utils';
import MeshCache from "@graphql-mesh/cache-localforage";
import { fetch as fetchFn } from '@whatwg-node/fetch';

import { MeshResolvedSource } from '@graphql-mesh/runtime';
import { MeshTransform, MeshPlugin } from '@graphql-mesh/types';
import GraphqlHandler from "@graphql-mesh/graphql"
import BlockTrackingTransform from "@graphprotocol/client-block-tracking";
import AutoPaginationTransform from "@graphprotocol/client-auto-pagination";
import StitchingMerger from "@graphql-mesh/merger-stitching";
import { printWithCache } from '@graphql-mesh/utils';
import { createMeshHTTPHandler, MeshHTTPHandler } from '@graphql-mesh/http';
import { getMesh, ExecuteMeshFn, SubscribeMeshFn, MeshContext as BaseMeshContext, MeshInstance } from '@graphql-mesh/runtime';
import { MeshStore, FsStoreStorageAdapter } from '@graphql-mesh/store';
import { path as pathModule } from '@graphql-mesh/cross-helpers';
import { ImportFn } from '@graphql-mesh/types';
import type { KingNftTypes } from './sources/king-nft/types';
import type { KingTypes } from './sources/king/types';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };



/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
};

export type Query = {
  nftholder?: Maybe<NFTHolder>;
  nftholders: Array<NFTHolder>;
  kingNFT?: Maybe<KingNFT>;
  kingNFTs: Array<KingNFT>;
  kingNFTToken?: Maybe<KingNFTToken>;
  kingNFTTokens: Array<KingNFTToken>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  transfer?: Maybe<Transfer>;
  transfers: Array<Transfer>;
  deposit?: Maybe<Deposit>;
  deposits: Array<Deposit>;
  withdraw?: Maybe<Withdraw>;
  withdraws: Array<Withdraw>;
  compound?: Maybe<Compound>;
  compounds: Array<Compound>;
  claimReward?: Maybe<ClaimReward>;
  claimRewards: Array<ClaimReward>;
  user?: Maybe<User>;
  users: Array<User>;
  kingData?: Maybe<KingData>;
  kingDatas: Array<KingData>;
  kingDayData?: Maybe<KingDayData>;
  kingDayDatas: Array<KingDayData>;
};


export type QuerynftholderArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftholdersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NFTHolder_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NFTHolder_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerykingNFTArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerykingNFTsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<KingNFT_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<KingNFT_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerykingNFTTokenArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerykingNFTTokensArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<KingNFTToken_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<KingNFTToken_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Query_metaArgs = {
  block?: InputMaybe<Block_height>;
};


export type QuerytransferArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytransfersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Transfer_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Transfer_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerydepositArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerydepositsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Deposit_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Deposit_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerywithdrawArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerywithdrawsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Withdraw_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Withdraw_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycompoundArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycompoundsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Compound_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Compound_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryclaimRewardArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryclaimRewardsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ClaimReward_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ClaimReward_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryuserArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryusersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<User_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<User_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerykingDataArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerykingDatasArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<KingData_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<KingData_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerykingDayDataArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerykingDayDatasArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<KingDayData_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<KingDayData_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};

export type Subscription = {
  nftholder?: Maybe<NFTHolder>;
  nftholders: Array<NFTHolder>;
  kingNFT?: Maybe<KingNFT>;
  kingNFTs: Array<KingNFT>;
  kingNFTToken?: Maybe<KingNFTToken>;
  kingNFTTokens: Array<KingNFTToken>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  transfer?: Maybe<Transfer>;
  transfers: Array<Transfer>;
  deposit?: Maybe<Deposit>;
  deposits: Array<Deposit>;
  withdraw?: Maybe<Withdraw>;
  withdraws: Array<Withdraw>;
  compound?: Maybe<Compound>;
  compounds: Array<Compound>;
  claimReward?: Maybe<ClaimReward>;
  claimRewards: Array<ClaimReward>;
  user?: Maybe<User>;
  users: Array<User>;
  kingData?: Maybe<KingData>;
  kingDatas: Array<KingData>;
  kingDayData?: Maybe<KingDayData>;
  kingDayDatas: Array<KingDayData>;
};


export type SubscriptionnftholderArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftholdersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NFTHolder_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NFTHolder_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionkingNFTArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionkingNFTsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<KingNFT_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<KingNFT_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionkingNFTTokenArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionkingNFTTokensArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<KingNFTToken_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<KingNFTToken_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscription_metaArgs = {
  block?: InputMaybe<Block_height>;
};


export type SubscriptiontransferArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontransfersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Transfer_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Transfer_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiondepositArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiondepositsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Deposit_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Deposit_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionwithdrawArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionwithdrawsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Withdraw_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Withdraw_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncompoundArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncompoundsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Compound_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Compound_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionclaimRewardArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionclaimRewardsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ClaimReward_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ClaimReward_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionuserArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionusersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<User_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<User_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionkingDataArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionkingDatasArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<KingData_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<KingData_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionkingDayDataArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionkingDayDatasArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<KingDayData_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<KingDayData_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};

export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_height = {
  hash?: InputMaybe<Scalars['Bytes']>;
  number?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
};

export type KingNFT = {
  id: Scalars['Bytes'];
  tokenID: Scalars['BigInt'];
  owner: NFTHolder;
};

export type KingNFTToken = {
  id: Scalars['Bytes'];
  totalSupply: Scalars['BigInt'];
  uri: Scalars['String'];
};

export type KingNFTToken_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  totalSupply?: InputMaybe<Scalars['BigInt']>;
  totalSupply_not?: InputMaybe<Scalars['BigInt']>;
  totalSupply_gt?: InputMaybe<Scalars['BigInt']>;
  totalSupply_lt?: InputMaybe<Scalars['BigInt']>;
  totalSupply_gte?: InputMaybe<Scalars['BigInt']>;
  totalSupply_lte?: InputMaybe<Scalars['BigInt']>;
  totalSupply_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalSupply_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  uri?: InputMaybe<Scalars['String']>;
  uri_not?: InputMaybe<Scalars['String']>;
  uri_gt?: InputMaybe<Scalars['String']>;
  uri_lt?: InputMaybe<Scalars['String']>;
  uri_gte?: InputMaybe<Scalars['String']>;
  uri_lte?: InputMaybe<Scalars['String']>;
  uri_in?: InputMaybe<Array<Scalars['String']>>;
  uri_not_in?: InputMaybe<Array<Scalars['String']>>;
  uri_contains?: InputMaybe<Scalars['String']>;
  uri_contains_nocase?: InputMaybe<Scalars['String']>;
  uri_not_contains?: InputMaybe<Scalars['String']>;
  uri_not_contains_nocase?: InputMaybe<Scalars['String']>;
  uri_starts_with?: InputMaybe<Scalars['String']>;
  uri_starts_with_nocase?: InputMaybe<Scalars['String']>;
  uri_not_starts_with?: InputMaybe<Scalars['String']>;
  uri_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  uri_ends_with?: InputMaybe<Scalars['String']>;
  uri_ends_with_nocase?: InputMaybe<Scalars['String']>;
  uri_not_ends_with?: InputMaybe<Scalars['String']>;
  uri_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<KingNFTToken_filter>>>;
  or?: InputMaybe<Array<InputMaybe<KingNFTToken_filter>>>;
};

export type KingNFTToken_orderBy =
  | 'id'
  | 'totalSupply'
  | 'uri';

export type KingNFT_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  tokenID?: InputMaybe<Scalars['BigInt']>;
  tokenID_not?: InputMaybe<Scalars['BigInt']>;
  tokenID_gt?: InputMaybe<Scalars['BigInt']>;
  tokenID_lt?: InputMaybe<Scalars['BigInt']>;
  tokenID_gte?: InputMaybe<Scalars['BigInt']>;
  tokenID_lte?: InputMaybe<Scalars['BigInt']>;
  tokenID_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenID_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  owner?: InputMaybe<Scalars['String']>;
  owner_not?: InputMaybe<Scalars['String']>;
  owner_gt?: InputMaybe<Scalars['String']>;
  owner_lt?: InputMaybe<Scalars['String']>;
  owner_gte?: InputMaybe<Scalars['String']>;
  owner_lte?: InputMaybe<Scalars['String']>;
  owner_in?: InputMaybe<Array<Scalars['String']>>;
  owner_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_contains?: InputMaybe<Scalars['String']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_not_contains?: InputMaybe<Scalars['String']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_starts_with?: InputMaybe<Scalars['String']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_ends_with?: InputMaybe<Scalars['String']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_?: InputMaybe<NFTHolder_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<KingNFT_filter>>>;
  or?: InputMaybe<Array<InputMaybe<KingNFT_filter>>>;
};

export type KingNFT_orderBy =
  | 'id'
  | 'tokenID'
  | 'owner'
  | 'owner__id';

export type NFTHolder = {
  id: Scalars['Bytes'];
  nfts: Array<KingNFT>;
};


export type NFTHoldernftsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<KingNFT_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<KingNFT_filter>;
};

export type NFTHolder_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  nfts_?: InputMaybe<KingNFT_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<NFTHolder_filter>>>;
  or?: InputMaybe<Array<InputMaybe<NFTHolder_filter>>>;
};

export type NFTHolder_orderBy =
  | 'id'
  | 'nfts';

/** Defines the order direction, either ascending or descending */
export type OrderDirection =
  | 'asc'
  | 'desc';

export type _Block_ = {
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export type _SubgraphErrorPolicy_ =
  /** Data will be returned even if the subgraph has indexing errors */
  | 'allow'
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  | 'deny';

export type ClaimReward = {
  id: Scalars['Bytes'];
  to: Scalars['Bytes'];
  value: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
};

export type ClaimReward_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  to?: InputMaybe<Scalars['Bytes']>;
  to_not?: InputMaybe<Scalars['Bytes']>;
  to_gt?: InputMaybe<Scalars['Bytes']>;
  to_lt?: InputMaybe<Scalars['Bytes']>;
  to_gte?: InputMaybe<Scalars['Bytes']>;
  to_lte?: InputMaybe<Scalars['Bytes']>;
  to_in?: InputMaybe<Array<Scalars['Bytes']>>;
  to_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  to_contains?: InputMaybe<Scalars['Bytes']>;
  to_not_contains?: InputMaybe<Scalars['Bytes']>;
  value?: InputMaybe<Scalars['BigInt']>;
  value_not?: InputMaybe<Scalars['BigInt']>;
  value_gt?: InputMaybe<Scalars['BigInt']>;
  value_lt?: InputMaybe<Scalars['BigInt']>;
  value_gte?: InputMaybe<Scalars['BigInt']>;
  value_lte?: InputMaybe<Scalars['BigInt']>;
  value_in?: InputMaybe<Array<Scalars['BigInt']>>;
  value_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ClaimReward_filter>>>;
  or?: InputMaybe<Array<InputMaybe<ClaimReward_filter>>>;
};

export type ClaimReward_orderBy =
  | 'id'
  | 'to'
  | 'value'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash';

export type Compound = {
  id: Scalars['Bytes'];
  to: Scalars['Bytes'];
  value: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
};

export type Compound_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  to?: InputMaybe<Scalars['Bytes']>;
  to_not?: InputMaybe<Scalars['Bytes']>;
  to_gt?: InputMaybe<Scalars['Bytes']>;
  to_lt?: InputMaybe<Scalars['Bytes']>;
  to_gte?: InputMaybe<Scalars['Bytes']>;
  to_lte?: InputMaybe<Scalars['Bytes']>;
  to_in?: InputMaybe<Array<Scalars['Bytes']>>;
  to_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  to_contains?: InputMaybe<Scalars['Bytes']>;
  to_not_contains?: InputMaybe<Scalars['Bytes']>;
  value?: InputMaybe<Scalars['BigInt']>;
  value_not?: InputMaybe<Scalars['BigInt']>;
  value_gt?: InputMaybe<Scalars['BigInt']>;
  value_lt?: InputMaybe<Scalars['BigInt']>;
  value_gte?: InputMaybe<Scalars['BigInt']>;
  value_lte?: InputMaybe<Scalars['BigInt']>;
  value_in?: InputMaybe<Array<Scalars['BigInt']>>;
  value_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Compound_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Compound_filter>>>;
};

export type Compound_orderBy =
  | 'id'
  | 'to'
  | 'value'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash';

export type Deposit = {
  id: Scalars['Bytes'];
  from: Scalars['Bytes'];
  value: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
};

export type Deposit_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  from?: InputMaybe<Scalars['Bytes']>;
  from_not?: InputMaybe<Scalars['Bytes']>;
  from_gt?: InputMaybe<Scalars['Bytes']>;
  from_lt?: InputMaybe<Scalars['Bytes']>;
  from_gte?: InputMaybe<Scalars['Bytes']>;
  from_lte?: InputMaybe<Scalars['Bytes']>;
  from_in?: InputMaybe<Array<Scalars['Bytes']>>;
  from_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  from_contains?: InputMaybe<Scalars['Bytes']>;
  from_not_contains?: InputMaybe<Scalars['Bytes']>;
  value?: InputMaybe<Scalars['BigInt']>;
  value_not?: InputMaybe<Scalars['BigInt']>;
  value_gt?: InputMaybe<Scalars['BigInt']>;
  value_lt?: InputMaybe<Scalars['BigInt']>;
  value_gte?: InputMaybe<Scalars['BigInt']>;
  value_lte?: InputMaybe<Scalars['BigInt']>;
  value_in?: InputMaybe<Array<Scalars['BigInt']>>;
  value_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Deposit_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Deposit_filter>>>;
};

export type Deposit_orderBy =
  | 'id'
  | 'from'
  | 'value'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash';

export type KingData = {
  id: Scalars['Bytes'];
  amountBurned: Scalars['BigInt'];
  amountClaimed: Scalars['BigInt'];
  amountStaked: Scalars['BigInt'];
  supply: Scalars['BigInt'];
  txCount: Scalars['BigInt'];
  holders: Scalars['BigInt'];
  ownership: Scalars['Bytes'];
};

export type KingData_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  amountBurned?: InputMaybe<Scalars['BigInt']>;
  amountBurned_not?: InputMaybe<Scalars['BigInt']>;
  amountBurned_gt?: InputMaybe<Scalars['BigInt']>;
  amountBurned_lt?: InputMaybe<Scalars['BigInt']>;
  amountBurned_gte?: InputMaybe<Scalars['BigInt']>;
  amountBurned_lte?: InputMaybe<Scalars['BigInt']>;
  amountBurned_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountBurned_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountClaimed?: InputMaybe<Scalars['BigInt']>;
  amountClaimed_not?: InputMaybe<Scalars['BigInt']>;
  amountClaimed_gt?: InputMaybe<Scalars['BigInt']>;
  amountClaimed_lt?: InputMaybe<Scalars['BigInt']>;
  amountClaimed_gte?: InputMaybe<Scalars['BigInt']>;
  amountClaimed_lte?: InputMaybe<Scalars['BigInt']>;
  amountClaimed_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountClaimed_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountStaked?: InputMaybe<Scalars['BigInt']>;
  amountStaked_not?: InputMaybe<Scalars['BigInt']>;
  amountStaked_gt?: InputMaybe<Scalars['BigInt']>;
  amountStaked_lt?: InputMaybe<Scalars['BigInt']>;
  amountStaked_gte?: InputMaybe<Scalars['BigInt']>;
  amountStaked_lte?: InputMaybe<Scalars['BigInt']>;
  amountStaked_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountStaked_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  supply?: InputMaybe<Scalars['BigInt']>;
  supply_not?: InputMaybe<Scalars['BigInt']>;
  supply_gt?: InputMaybe<Scalars['BigInt']>;
  supply_lt?: InputMaybe<Scalars['BigInt']>;
  supply_gte?: InputMaybe<Scalars['BigInt']>;
  supply_lte?: InputMaybe<Scalars['BigInt']>;
  supply_in?: InputMaybe<Array<Scalars['BigInt']>>;
  supply_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount?: InputMaybe<Scalars['BigInt']>;
  txCount_not?: InputMaybe<Scalars['BigInt']>;
  txCount_gt?: InputMaybe<Scalars['BigInt']>;
  txCount_lt?: InputMaybe<Scalars['BigInt']>;
  txCount_gte?: InputMaybe<Scalars['BigInt']>;
  txCount_lte?: InputMaybe<Scalars['BigInt']>;
  txCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  holders?: InputMaybe<Scalars['BigInt']>;
  holders_not?: InputMaybe<Scalars['BigInt']>;
  holders_gt?: InputMaybe<Scalars['BigInt']>;
  holders_lt?: InputMaybe<Scalars['BigInt']>;
  holders_gte?: InputMaybe<Scalars['BigInt']>;
  holders_lte?: InputMaybe<Scalars['BigInt']>;
  holders_in?: InputMaybe<Array<Scalars['BigInt']>>;
  holders_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ownership?: InputMaybe<Scalars['Bytes']>;
  ownership_not?: InputMaybe<Scalars['Bytes']>;
  ownership_gt?: InputMaybe<Scalars['Bytes']>;
  ownership_lt?: InputMaybe<Scalars['Bytes']>;
  ownership_gte?: InputMaybe<Scalars['Bytes']>;
  ownership_lte?: InputMaybe<Scalars['Bytes']>;
  ownership_in?: InputMaybe<Array<Scalars['Bytes']>>;
  ownership_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  ownership_contains?: InputMaybe<Scalars['Bytes']>;
  ownership_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<KingData_filter>>>;
  or?: InputMaybe<Array<InputMaybe<KingData_filter>>>;
};

export type KingData_orderBy =
  | 'id'
  | 'amountBurned'
  | 'amountClaimed'
  | 'amountStaked'
  | 'supply'
  | 'txCount'
  | 'holders'
  | 'ownership';

export type KingDayData = {
  id: Scalars['Bytes'];
  date: Scalars['Int'];
  amountBurned: Scalars['BigInt'];
  amountClaimed: Scalars['BigInt'];
  amountStaked: Scalars['BigInt'];
  supply: Scalars['BigInt'];
  txCount: Scalars['BigInt'];
  holders: Scalars['BigInt'];
};

export type KingDayData_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  date?: InputMaybe<Scalars['Int']>;
  date_not?: InputMaybe<Scalars['Int']>;
  date_gt?: InputMaybe<Scalars['Int']>;
  date_lt?: InputMaybe<Scalars['Int']>;
  date_gte?: InputMaybe<Scalars['Int']>;
  date_lte?: InputMaybe<Scalars['Int']>;
  date_in?: InputMaybe<Array<Scalars['Int']>>;
  date_not_in?: InputMaybe<Array<Scalars['Int']>>;
  amountBurned?: InputMaybe<Scalars['BigInt']>;
  amountBurned_not?: InputMaybe<Scalars['BigInt']>;
  amountBurned_gt?: InputMaybe<Scalars['BigInt']>;
  amountBurned_lt?: InputMaybe<Scalars['BigInt']>;
  amountBurned_gte?: InputMaybe<Scalars['BigInt']>;
  amountBurned_lte?: InputMaybe<Scalars['BigInt']>;
  amountBurned_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountBurned_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountClaimed?: InputMaybe<Scalars['BigInt']>;
  amountClaimed_not?: InputMaybe<Scalars['BigInt']>;
  amountClaimed_gt?: InputMaybe<Scalars['BigInt']>;
  amountClaimed_lt?: InputMaybe<Scalars['BigInt']>;
  amountClaimed_gte?: InputMaybe<Scalars['BigInt']>;
  amountClaimed_lte?: InputMaybe<Scalars['BigInt']>;
  amountClaimed_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountClaimed_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountStaked?: InputMaybe<Scalars['BigInt']>;
  amountStaked_not?: InputMaybe<Scalars['BigInt']>;
  amountStaked_gt?: InputMaybe<Scalars['BigInt']>;
  amountStaked_lt?: InputMaybe<Scalars['BigInt']>;
  amountStaked_gte?: InputMaybe<Scalars['BigInt']>;
  amountStaked_lte?: InputMaybe<Scalars['BigInt']>;
  amountStaked_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountStaked_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  supply?: InputMaybe<Scalars['BigInt']>;
  supply_not?: InputMaybe<Scalars['BigInt']>;
  supply_gt?: InputMaybe<Scalars['BigInt']>;
  supply_lt?: InputMaybe<Scalars['BigInt']>;
  supply_gte?: InputMaybe<Scalars['BigInt']>;
  supply_lte?: InputMaybe<Scalars['BigInt']>;
  supply_in?: InputMaybe<Array<Scalars['BigInt']>>;
  supply_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount?: InputMaybe<Scalars['BigInt']>;
  txCount_not?: InputMaybe<Scalars['BigInt']>;
  txCount_gt?: InputMaybe<Scalars['BigInt']>;
  txCount_lt?: InputMaybe<Scalars['BigInt']>;
  txCount_gte?: InputMaybe<Scalars['BigInt']>;
  txCount_lte?: InputMaybe<Scalars['BigInt']>;
  txCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  holders?: InputMaybe<Scalars['BigInt']>;
  holders_not?: InputMaybe<Scalars['BigInt']>;
  holders_gt?: InputMaybe<Scalars['BigInt']>;
  holders_lt?: InputMaybe<Scalars['BigInt']>;
  holders_gte?: InputMaybe<Scalars['BigInt']>;
  holders_lte?: InputMaybe<Scalars['BigInt']>;
  holders_in?: InputMaybe<Array<Scalars['BigInt']>>;
  holders_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<KingDayData_filter>>>;
  or?: InputMaybe<Array<InputMaybe<KingDayData_filter>>>;
};

export type KingDayData_orderBy =
  | 'id'
  | 'date'
  | 'amountBurned'
  | 'amountClaimed'
  | 'amountStaked'
  | 'supply'
  | 'txCount'
  | 'holders';

export type Transfer = {
  id: Scalars['Bytes'];
  from: Scalars['Bytes'];
  to: Scalars['Bytes'];
  value: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
};

export type Transfer_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  from?: InputMaybe<Scalars['Bytes']>;
  from_not?: InputMaybe<Scalars['Bytes']>;
  from_gt?: InputMaybe<Scalars['Bytes']>;
  from_lt?: InputMaybe<Scalars['Bytes']>;
  from_gte?: InputMaybe<Scalars['Bytes']>;
  from_lte?: InputMaybe<Scalars['Bytes']>;
  from_in?: InputMaybe<Array<Scalars['Bytes']>>;
  from_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  from_contains?: InputMaybe<Scalars['Bytes']>;
  from_not_contains?: InputMaybe<Scalars['Bytes']>;
  to?: InputMaybe<Scalars['Bytes']>;
  to_not?: InputMaybe<Scalars['Bytes']>;
  to_gt?: InputMaybe<Scalars['Bytes']>;
  to_lt?: InputMaybe<Scalars['Bytes']>;
  to_gte?: InputMaybe<Scalars['Bytes']>;
  to_lte?: InputMaybe<Scalars['Bytes']>;
  to_in?: InputMaybe<Array<Scalars['Bytes']>>;
  to_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  to_contains?: InputMaybe<Scalars['Bytes']>;
  to_not_contains?: InputMaybe<Scalars['Bytes']>;
  value?: InputMaybe<Scalars['BigInt']>;
  value_not?: InputMaybe<Scalars['BigInt']>;
  value_gt?: InputMaybe<Scalars['BigInt']>;
  value_lt?: InputMaybe<Scalars['BigInt']>;
  value_gte?: InputMaybe<Scalars['BigInt']>;
  value_lte?: InputMaybe<Scalars['BigInt']>;
  value_in?: InputMaybe<Array<Scalars['BigInt']>>;
  value_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Transfer_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Transfer_filter>>>;
};

export type Transfer_orderBy =
  | 'id'
  | 'from'
  | 'to'
  | 'value'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash';

export type User = {
  id: Scalars['Bytes'];
  vested: Scalars['Boolean'];
  walletAmount: Scalars['BigInt'];
  stakedAmount: Scalars['BigInt'];
  totalAmount: Scalars['BigInt'];
  lockedUntil: Scalars['BigInt'];
  kingpassActiveUntil: Scalars['BigInt'];
  lockedAmountForKingpass: Scalars['BigInt'];
};

export type User_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  vested?: InputMaybe<Scalars['Boolean']>;
  vested_not?: InputMaybe<Scalars['Boolean']>;
  vested_in?: InputMaybe<Array<Scalars['Boolean']>>;
  vested_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  walletAmount?: InputMaybe<Scalars['BigInt']>;
  walletAmount_not?: InputMaybe<Scalars['BigInt']>;
  walletAmount_gt?: InputMaybe<Scalars['BigInt']>;
  walletAmount_lt?: InputMaybe<Scalars['BigInt']>;
  walletAmount_gte?: InputMaybe<Scalars['BigInt']>;
  walletAmount_lte?: InputMaybe<Scalars['BigInt']>;
  walletAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  walletAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stakedAmount?: InputMaybe<Scalars['BigInt']>;
  stakedAmount_not?: InputMaybe<Scalars['BigInt']>;
  stakedAmount_gt?: InputMaybe<Scalars['BigInt']>;
  stakedAmount_lt?: InputMaybe<Scalars['BigInt']>;
  stakedAmount_gte?: InputMaybe<Scalars['BigInt']>;
  stakedAmount_lte?: InputMaybe<Scalars['BigInt']>;
  stakedAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stakedAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalAmount?: InputMaybe<Scalars['BigInt']>;
  totalAmount_not?: InputMaybe<Scalars['BigInt']>;
  totalAmount_gt?: InputMaybe<Scalars['BigInt']>;
  totalAmount_lt?: InputMaybe<Scalars['BigInt']>;
  totalAmount_gte?: InputMaybe<Scalars['BigInt']>;
  totalAmount_lte?: InputMaybe<Scalars['BigInt']>;
  totalAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lockedUntil?: InputMaybe<Scalars['BigInt']>;
  lockedUntil_not?: InputMaybe<Scalars['BigInt']>;
  lockedUntil_gt?: InputMaybe<Scalars['BigInt']>;
  lockedUntil_lt?: InputMaybe<Scalars['BigInt']>;
  lockedUntil_gte?: InputMaybe<Scalars['BigInt']>;
  lockedUntil_lte?: InputMaybe<Scalars['BigInt']>;
  lockedUntil_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lockedUntil_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  kingpassActiveUntil?: InputMaybe<Scalars['BigInt']>;
  kingpassActiveUntil_not?: InputMaybe<Scalars['BigInt']>;
  kingpassActiveUntil_gt?: InputMaybe<Scalars['BigInt']>;
  kingpassActiveUntil_lt?: InputMaybe<Scalars['BigInt']>;
  kingpassActiveUntil_gte?: InputMaybe<Scalars['BigInt']>;
  kingpassActiveUntil_lte?: InputMaybe<Scalars['BigInt']>;
  kingpassActiveUntil_in?: InputMaybe<Array<Scalars['BigInt']>>;
  kingpassActiveUntil_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lockedAmountForKingpass?: InputMaybe<Scalars['BigInt']>;
  lockedAmountForKingpass_not?: InputMaybe<Scalars['BigInt']>;
  lockedAmountForKingpass_gt?: InputMaybe<Scalars['BigInt']>;
  lockedAmountForKingpass_lt?: InputMaybe<Scalars['BigInt']>;
  lockedAmountForKingpass_gte?: InputMaybe<Scalars['BigInt']>;
  lockedAmountForKingpass_lte?: InputMaybe<Scalars['BigInt']>;
  lockedAmountForKingpass_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lockedAmountForKingpass_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<User_filter>>>;
  or?: InputMaybe<Array<InputMaybe<User_filter>>>;
};

export type User_orderBy =
  | 'id'
  | 'vested'
  | 'walletAmount'
  | 'stakedAmount'
  | 'totalAmount'
  | 'lockedUntil'
  | 'kingpassActiveUntil'
  | 'lockedAmountForKingpass';

export type Withdraw = {
  id: Scalars['Bytes'];
  to: Scalars['Bytes'];
  value: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
};

export type Withdraw_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  to?: InputMaybe<Scalars['Bytes']>;
  to_not?: InputMaybe<Scalars['Bytes']>;
  to_gt?: InputMaybe<Scalars['Bytes']>;
  to_lt?: InputMaybe<Scalars['Bytes']>;
  to_gte?: InputMaybe<Scalars['Bytes']>;
  to_lte?: InputMaybe<Scalars['Bytes']>;
  to_in?: InputMaybe<Array<Scalars['Bytes']>>;
  to_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  to_contains?: InputMaybe<Scalars['Bytes']>;
  to_not_contains?: InputMaybe<Scalars['Bytes']>;
  value?: InputMaybe<Scalars['BigInt']>;
  value_not?: InputMaybe<Scalars['BigInt']>;
  value_gt?: InputMaybe<Scalars['BigInt']>;
  value_lt?: InputMaybe<Scalars['BigInt']>;
  value_gte?: InputMaybe<Scalars['BigInt']>;
  value_lte?: InputMaybe<Scalars['BigInt']>;
  value_in?: InputMaybe<Array<Scalars['BigInt']>>;
  value_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Withdraw_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Withdraw_filter>>>;
};

export type Withdraw_orderBy =
  | 'id'
  | 'to'
  | 'value'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash';

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string | ((fieldNode: FieldNode) => SelectionSetNode);
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Query: ResolverTypeWrapper<{}>;
  Subscription: ResolverTypeWrapper<{}>;
  BigDecimal: ResolverTypeWrapper<Scalars['BigDecimal']>;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']>;
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Bytes: ResolverTypeWrapper<Scalars['Bytes']>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  KingNFT: ResolverTypeWrapper<KingNFT>;
  KingNFTToken: ResolverTypeWrapper<KingNFTToken>;
  KingNFTToken_filter: KingNFTToken_filter;
  KingNFTToken_orderBy: KingNFTToken_orderBy;
  KingNFT_filter: KingNFT_filter;
  KingNFT_orderBy: KingNFT_orderBy;
  NFTHolder: ResolverTypeWrapper<NFTHolder>;
  NFTHolder_filter: NFTHolder_filter;
  NFTHolder_orderBy: NFTHolder_orderBy;
  OrderDirection: OrderDirection;
  String: ResolverTypeWrapper<Scalars['String']>;
  _Block_: ResolverTypeWrapper<_Block_>;
  _Meta_: ResolverTypeWrapper<_Meta_>;
  _SubgraphErrorPolicy_: _SubgraphErrorPolicy_;
  ClaimReward: ResolverTypeWrapper<ClaimReward>;
  ClaimReward_filter: ClaimReward_filter;
  ClaimReward_orderBy: ClaimReward_orderBy;
  Compound: ResolverTypeWrapper<Compound>;
  Compound_filter: Compound_filter;
  Compound_orderBy: Compound_orderBy;
  Deposit: ResolverTypeWrapper<Deposit>;
  Deposit_filter: Deposit_filter;
  Deposit_orderBy: Deposit_orderBy;
  KingData: ResolverTypeWrapper<KingData>;
  KingData_filter: KingData_filter;
  KingData_orderBy: KingData_orderBy;
  KingDayData: ResolverTypeWrapper<KingDayData>;
  KingDayData_filter: KingDayData_filter;
  KingDayData_orderBy: KingDayData_orderBy;
  Transfer: ResolverTypeWrapper<Transfer>;
  Transfer_filter: Transfer_filter;
  Transfer_orderBy: Transfer_orderBy;
  User: ResolverTypeWrapper<User>;
  User_filter: User_filter;
  User_orderBy: User_orderBy;
  Withdraw: ResolverTypeWrapper<Withdraw>;
  Withdraw_filter: Withdraw_filter;
  Withdraw_orderBy: Withdraw_orderBy;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Query: {};
  Subscription: {};
  BigDecimal: Scalars['BigDecimal'];
  BigInt: Scalars['BigInt'];
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: Scalars['Boolean'];
  Bytes: Scalars['Bytes'];
  Float: Scalars['Float'];
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  KingNFT: KingNFT;
  KingNFTToken: KingNFTToken;
  KingNFTToken_filter: KingNFTToken_filter;
  KingNFT_filter: KingNFT_filter;
  NFTHolder: NFTHolder;
  NFTHolder_filter: NFTHolder_filter;
  String: Scalars['String'];
  _Block_: _Block_;
  _Meta_: _Meta_;
  ClaimReward: ClaimReward;
  ClaimReward_filter: ClaimReward_filter;
  Compound: Compound;
  Compound_filter: Compound_filter;
  Deposit: Deposit;
  Deposit_filter: Deposit_filter;
  KingData: KingData;
  KingData_filter: KingData_filter;
  KingDayData: KingDayData;
  KingDayData_filter: KingDayData_filter;
  Transfer: Transfer;
  Transfer_filter: Transfer_filter;
  User: User;
  User_filter: User_filter;
  Withdraw: Withdraw;
  Withdraw_filter: Withdraw_filter;
}>;

export type entityDirectiveArgs = { };

export type entityDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = entityDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type subgraphIdDirectiveArgs = {
  id: Scalars['String'];
};

export type subgraphIdDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = subgraphIdDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type derivedFromDirectiveArgs = {
  field: Scalars['String'];
};

export type derivedFromDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = derivedFromDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type QueryResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  nftholder?: Resolver<Maybe<ResolversTypes['NFTHolder']>, ParentType, ContextType, RequireFields<QuerynftholderArgs, 'id' | 'subgraphError'>>;
  nftholders?: Resolver<Array<ResolversTypes['NFTHolder']>, ParentType, ContextType, RequireFields<QuerynftholdersArgs, 'skip' | 'first' | 'subgraphError'>>;
  kingNFT?: Resolver<Maybe<ResolversTypes['KingNFT']>, ParentType, ContextType, RequireFields<QuerykingNFTArgs, 'id' | 'subgraphError'>>;
  kingNFTs?: Resolver<Array<ResolversTypes['KingNFT']>, ParentType, ContextType, RequireFields<QuerykingNFTsArgs, 'skip' | 'first' | 'subgraphError'>>;
  kingNFTToken?: Resolver<Maybe<ResolversTypes['KingNFTToken']>, ParentType, ContextType, RequireFields<QuerykingNFTTokenArgs, 'id' | 'subgraphError'>>;
  kingNFTTokens?: Resolver<Array<ResolversTypes['KingNFTToken']>, ParentType, ContextType, RequireFields<QuerykingNFTTokensArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: Resolver<Maybe<ResolversTypes['_Meta_']>, ParentType, ContextType, Partial<Query_metaArgs>>;
  transfer?: Resolver<Maybe<ResolversTypes['Transfer']>, ParentType, ContextType, RequireFields<QuerytransferArgs, 'id' | 'subgraphError'>>;
  transfers?: Resolver<Array<ResolversTypes['Transfer']>, ParentType, ContextType, RequireFields<QuerytransfersArgs, 'skip' | 'first' | 'subgraphError'>>;
  deposit?: Resolver<Maybe<ResolversTypes['Deposit']>, ParentType, ContextType, RequireFields<QuerydepositArgs, 'id' | 'subgraphError'>>;
  deposits?: Resolver<Array<ResolversTypes['Deposit']>, ParentType, ContextType, RequireFields<QuerydepositsArgs, 'skip' | 'first' | 'subgraphError'>>;
  withdraw?: Resolver<Maybe<ResolversTypes['Withdraw']>, ParentType, ContextType, RequireFields<QuerywithdrawArgs, 'id' | 'subgraphError'>>;
  withdraws?: Resolver<Array<ResolversTypes['Withdraw']>, ParentType, ContextType, RequireFields<QuerywithdrawsArgs, 'skip' | 'first' | 'subgraphError'>>;
  compound?: Resolver<Maybe<ResolversTypes['Compound']>, ParentType, ContextType, RequireFields<QuerycompoundArgs, 'id' | 'subgraphError'>>;
  compounds?: Resolver<Array<ResolversTypes['Compound']>, ParentType, ContextType, RequireFields<QuerycompoundsArgs, 'skip' | 'first' | 'subgraphError'>>;
  claimReward?: Resolver<Maybe<ResolversTypes['ClaimReward']>, ParentType, ContextType, RequireFields<QueryclaimRewardArgs, 'id' | 'subgraphError'>>;
  claimRewards?: Resolver<Array<ResolversTypes['ClaimReward']>, ParentType, ContextType, RequireFields<QueryclaimRewardsArgs, 'skip' | 'first' | 'subgraphError'>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryuserArgs, 'id' | 'subgraphError'>>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryusersArgs, 'skip' | 'first' | 'subgraphError'>>;
  kingData?: Resolver<Maybe<ResolversTypes['KingData']>, ParentType, ContextType, RequireFields<QuerykingDataArgs, 'id' | 'subgraphError'>>;
  kingDatas?: Resolver<Array<ResolversTypes['KingData']>, ParentType, ContextType, RequireFields<QuerykingDatasArgs, 'skip' | 'first' | 'subgraphError'>>;
  kingDayData?: Resolver<Maybe<ResolversTypes['KingDayData']>, ParentType, ContextType, RequireFields<QuerykingDayDataArgs, 'id' | 'subgraphError'>>;
  kingDayDatas?: Resolver<Array<ResolversTypes['KingDayData']>, ParentType, ContextType, RequireFields<QuerykingDayDatasArgs, 'skip' | 'first' | 'subgraphError'>>;
}>;

export type SubscriptionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = ResolversObject<{
  nftholder?: SubscriptionResolver<Maybe<ResolversTypes['NFTHolder']>, "nftholder", ParentType, ContextType, RequireFields<SubscriptionnftholderArgs, 'id' | 'subgraphError'>>;
  nftholders?: SubscriptionResolver<Array<ResolversTypes['NFTHolder']>, "nftholders", ParentType, ContextType, RequireFields<SubscriptionnftholdersArgs, 'skip' | 'first' | 'subgraphError'>>;
  kingNFT?: SubscriptionResolver<Maybe<ResolversTypes['KingNFT']>, "kingNFT", ParentType, ContextType, RequireFields<SubscriptionkingNFTArgs, 'id' | 'subgraphError'>>;
  kingNFTs?: SubscriptionResolver<Array<ResolversTypes['KingNFT']>, "kingNFTs", ParentType, ContextType, RequireFields<SubscriptionkingNFTsArgs, 'skip' | 'first' | 'subgraphError'>>;
  kingNFTToken?: SubscriptionResolver<Maybe<ResolversTypes['KingNFTToken']>, "kingNFTToken", ParentType, ContextType, RequireFields<SubscriptionkingNFTTokenArgs, 'id' | 'subgraphError'>>;
  kingNFTTokens?: SubscriptionResolver<Array<ResolversTypes['KingNFTToken']>, "kingNFTTokens", ParentType, ContextType, RequireFields<SubscriptionkingNFTTokensArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: SubscriptionResolver<Maybe<ResolversTypes['_Meta_']>, "_meta", ParentType, ContextType, Partial<Subscription_metaArgs>>;
  transfer?: SubscriptionResolver<Maybe<ResolversTypes['Transfer']>, "transfer", ParentType, ContextType, RequireFields<SubscriptiontransferArgs, 'id' | 'subgraphError'>>;
  transfers?: SubscriptionResolver<Array<ResolversTypes['Transfer']>, "transfers", ParentType, ContextType, RequireFields<SubscriptiontransfersArgs, 'skip' | 'first' | 'subgraphError'>>;
  deposit?: SubscriptionResolver<Maybe<ResolversTypes['Deposit']>, "deposit", ParentType, ContextType, RequireFields<SubscriptiondepositArgs, 'id' | 'subgraphError'>>;
  deposits?: SubscriptionResolver<Array<ResolversTypes['Deposit']>, "deposits", ParentType, ContextType, RequireFields<SubscriptiondepositsArgs, 'skip' | 'first' | 'subgraphError'>>;
  withdraw?: SubscriptionResolver<Maybe<ResolversTypes['Withdraw']>, "withdraw", ParentType, ContextType, RequireFields<SubscriptionwithdrawArgs, 'id' | 'subgraphError'>>;
  withdraws?: SubscriptionResolver<Array<ResolversTypes['Withdraw']>, "withdraws", ParentType, ContextType, RequireFields<SubscriptionwithdrawsArgs, 'skip' | 'first' | 'subgraphError'>>;
  compound?: SubscriptionResolver<Maybe<ResolversTypes['Compound']>, "compound", ParentType, ContextType, RequireFields<SubscriptioncompoundArgs, 'id' | 'subgraphError'>>;
  compounds?: SubscriptionResolver<Array<ResolversTypes['Compound']>, "compounds", ParentType, ContextType, RequireFields<SubscriptioncompoundsArgs, 'skip' | 'first' | 'subgraphError'>>;
  claimReward?: SubscriptionResolver<Maybe<ResolversTypes['ClaimReward']>, "claimReward", ParentType, ContextType, RequireFields<SubscriptionclaimRewardArgs, 'id' | 'subgraphError'>>;
  claimRewards?: SubscriptionResolver<Array<ResolversTypes['ClaimReward']>, "claimRewards", ParentType, ContextType, RequireFields<SubscriptionclaimRewardsArgs, 'skip' | 'first' | 'subgraphError'>>;
  user?: SubscriptionResolver<Maybe<ResolversTypes['User']>, "user", ParentType, ContextType, RequireFields<SubscriptionuserArgs, 'id' | 'subgraphError'>>;
  users?: SubscriptionResolver<Array<ResolversTypes['User']>, "users", ParentType, ContextType, RequireFields<SubscriptionusersArgs, 'skip' | 'first' | 'subgraphError'>>;
  kingData?: SubscriptionResolver<Maybe<ResolversTypes['KingData']>, "kingData", ParentType, ContextType, RequireFields<SubscriptionkingDataArgs, 'id' | 'subgraphError'>>;
  kingDatas?: SubscriptionResolver<Array<ResolversTypes['KingData']>, "kingDatas", ParentType, ContextType, RequireFields<SubscriptionkingDatasArgs, 'skip' | 'first' | 'subgraphError'>>;
  kingDayData?: SubscriptionResolver<Maybe<ResolversTypes['KingDayData']>, "kingDayData", ParentType, ContextType, RequireFields<SubscriptionkingDayDataArgs, 'id' | 'subgraphError'>>;
  kingDayDatas?: SubscriptionResolver<Array<ResolversTypes['KingDayData']>, "kingDayDatas", ParentType, ContextType, RequireFields<SubscriptionkingDayDatasArgs, 'skip' | 'first' | 'subgraphError'>>;
}>;

export interface BigDecimalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigDecimal'], any> {
  name: 'BigDecimal';
}

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export interface BytesScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Bytes'], any> {
  name: 'Bytes';
}

export type KingNFTResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['KingNFT'] = ResolversParentTypes['KingNFT']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  tokenID?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['NFTHolder'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type KingNFTTokenResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['KingNFTToken'] = ResolversParentTypes['KingNFTToken']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  totalSupply?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  uri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NFTHolderResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['NFTHolder'] = ResolversParentTypes['NFTHolder']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  nfts?: Resolver<Array<ResolversTypes['KingNFT']>, ParentType, ContextType, RequireFields<NFTHoldernftsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Block_Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['_Block_'] = ResolversParentTypes['_Block_']> = ResolversObject<{
  hash?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Meta_Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['_Meta_'] = ResolversParentTypes['_Meta_']> = ResolversObject<{
  block?: Resolver<ResolversTypes['_Block_'], ParentType, ContextType>;
  deployment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasIndexingErrors?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ClaimRewardResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ClaimReward'] = ResolversParentTypes['ClaimReward']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompoundResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Compound'] = ResolversParentTypes['Compound']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DepositResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Deposit'] = ResolversParentTypes['Deposit']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type KingDataResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['KingData'] = ResolversParentTypes['KingData']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amountBurned?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amountClaimed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amountStaked?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  supply?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  txCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  holders?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  ownership?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type KingDayDataResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['KingDayData'] = ResolversParentTypes['KingDayData']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  amountBurned?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amountClaimed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amountStaked?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  supply?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  txCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  holders?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TransferResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Transfer'] = ResolversParentTypes['Transfer']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  vested?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  walletAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  stakedAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  lockedUntil?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  kingpassActiveUntil?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  lockedAmountForKingpass?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WithdrawResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Withdraw'] = ResolversParentTypes['Withdraw']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = MeshContext> = ResolversObject<{
  Query?: QueryResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  BigDecimal?: GraphQLScalarType;
  BigInt?: GraphQLScalarType;
  Bytes?: GraphQLScalarType;
  KingNFT?: KingNFTResolvers<ContextType>;
  KingNFTToken?: KingNFTTokenResolvers<ContextType>;
  NFTHolder?: NFTHolderResolvers<ContextType>;
  _Block_?: _Block_Resolvers<ContextType>;
  _Meta_?: _Meta_Resolvers<ContextType>;
  ClaimReward?: ClaimRewardResolvers<ContextType>;
  Compound?: CompoundResolvers<ContextType>;
  Deposit?: DepositResolvers<ContextType>;
  KingData?: KingDataResolvers<ContextType>;
  KingDayData?: KingDayDataResolvers<ContextType>;
  Transfer?: TransferResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  Withdraw?: WithdrawResolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = MeshContext> = ResolversObject<{
  entity?: entityDirectiveResolver<any, any, ContextType>;
  subgraphId?: subgraphIdDirectiveResolver<any, any, ContextType>;
  derivedFrom?: derivedFromDirectiveResolver<any, any, ContextType>;
}>;

export type MeshContext = KingNftTypes.Context & KingTypes.Context & BaseMeshContext;


import { fileURLToPath } from '@graphql-mesh/utils';
const baseDir = pathModule.join(pathModule.dirname(fileURLToPath(import.meta.url)), '..');

const importFn: ImportFn = <T>(moduleId: string) => {
  const relativeModuleId = (pathModule.isAbsolute(moduleId) ? pathModule.relative(baseDir, moduleId) : moduleId).split('\\').join('/').replace(baseDir + '/', '');
  switch(relativeModuleId) {
    case ".graphclient/sources/king-nft/introspectionSchema":
      return import("./sources/king-nft/introspectionSchema") as T;
    
    case ".graphclient/sources/king/introspectionSchema":
      return import("./sources/king/introspectionSchema") as T;
    
    default:
      return Promise.reject(new Error(`Cannot find module '${relativeModuleId}'.`));
  }
};

const rootStore = new MeshStore('.graphclient', new FsStoreStorageAdapter({
  cwd: baseDir,
  importFn,
  fileType: "ts",
}), {
  readonly: true,
  validate: false
});

export const rawServeConfig: YamlConfig.Config['serve'] = undefined as any
export async function getMeshOptions(): Promise<GetMeshOptions> {
const pubsub = new PubSub();
const sourcesStore = rootStore.child('sources');
const logger = new DefaultLogger("GraphClient");
const cache = new (MeshCache as any)({
      ...({} as any),
      importFn,
      store: rootStore.child('cache'),
      pubsub,
      logger,
    } as any)

const sources: MeshResolvedSource[] = [];
const transforms: MeshTransform[] = [];
const additionalEnvelopPlugins: MeshPlugin<any>[] = [];
const kingTransforms = [];
const kingNftTransforms = [];
const additionalTypeDefs = [] as any[];
const kingHandler = new GraphqlHandler({
              name: "king",
              config: {"endpoint":"https://api.thegraph.com/subgraphs/name/simon0x/king"},
              baseDir,
              cache,
              pubsub,
              store: sourcesStore.child("king"),
              logger: logger.child("king"),
              importFn,
            });
const kingNftHandler = new GraphqlHandler({
              name: "king-nft",
              config: {"endpoint":"https://api.thegraph.com/subgraphs/name/simon0x/king-nft"},
              baseDir,
              cache,
              pubsub,
              store: sourcesStore.child("king-nft"),
              logger: logger.child("king-nft"),
              importFn,
            });
kingTransforms[0] = new BlockTrackingTransform({
                  apiName: "king",
                  config: {"validateSchema":true},
                  baseDir,
                  cache,
                  pubsub,
                  importFn,
                  logger,
                });
kingTransforms[1] = new AutoPaginationTransform({
                  apiName: "king",
                  config: {"validateSchema":true},
                  baseDir,
                  cache,
                  pubsub,
                  importFn,
                  logger,
                });
kingNftTransforms[0] = new BlockTrackingTransform({
                  apiName: "king-nft",
                  config: {"validateSchema":true},
                  baseDir,
                  cache,
                  pubsub,
                  importFn,
                  logger,
                });
kingNftTransforms[1] = new AutoPaginationTransform({
                  apiName: "king-nft",
                  config: {"validateSchema":true},
                  baseDir,
                  cache,
                  pubsub,
                  importFn,
                  logger,
                });
sources[0] = {
          name: 'king',
          handler: kingHandler,
          transforms: kingTransforms
        }
sources[1] = {
          name: 'king-nft',
          handler: kingNftHandler,
          transforms: kingNftTransforms
        }
const additionalResolvers = [] as any[]
const merger = new(StitchingMerger as any)({
        cache,
        pubsub,
        logger: logger.child('stitchingMerger'),
        store: rootStore.child('stitchingMerger')
      })

  return {
    sources,
    transforms,
    additionalTypeDefs,
    additionalResolvers,
    cache,
    pubsub,
    merger,
    logger,
    additionalEnvelopPlugins,
    get documents() {
      return [
      {
        document: KingPassHoldersDocument,
        get rawSDL() {
          return printWithCache(KingPassHoldersDocument);
        },
        location: 'KingPassHoldersDocument.graphql'
      },{
        document: KingDayDataDocument,
        get rawSDL() {
          return printWithCache(KingDayDataDocument);
        },
        location: 'KingDayDataDocument.graphql'
      },{
        document: UserDocument,
        get rawSDL() {
          return printWithCache(UserDocument);
        },
        location: 'UserDocument.graphql'
      },{
        document: UsersDocument,
        get rawSDL() {
          return printWithCache(UsersDocument);
        },
        location: 'UsersDocument.graphql'
      }
    ];
    },
    fetchFn,
  };
}

export function createBuiltMeshHTTPHandler(): MeshHTTPHandler<MeshContext> {
  return createMeshHTTPHandler<MeshContext>({
    baseDir,
    getBuiltMesh: getBuiltGraphClient,
    rawServeConfig: undefined,
  })
}


let meshInstance$: Promise<MeshInstance> | undefined;

export function getBuiltGraphClient(): Promise<MeshInstance> {
  if (meshInstance$ == null) {
    meshInstance$ = getMeshOptions().then(meshOptions => getMesh(meshOptions)).then(mesh => {
      const id = mesh.pubsub.subscribe('destroy', () => {
        meshInstance$ = undefined;
        mesh.pubsub.unsubscribe(id);
      });
      return mesh;
    });
  }
  return meshInstance$;
}

export const execute: ExecuteMeshFn = (...args) => getBuiltGraphClient().then(({ execute }) => execute(...args));

export const subscribe: SubscribeMeshFn = (...args) => getBuiltGraphClient().then(({ subscribe }) => subscribe(...args));
export function getBuiltGraphSDK<TGlobalContext = any, TOperationContext = any>(globalContext?: TGlobalContext) {
  const sdkRequester$ = getBuiltGraphClient().then(({ sdkRequesterFactory }) => sdkRequesterFactory(globalContext));
  return getSdk<TOperationContext, TGlobalContext>((...args) => sdkRequester$.then(sdkRequester => sdkRequester(...args)));
}
export type KingPassHoldersQueryVariables = Exact<{
  first: Scalars['Int'];
  orderDirection: OrderDirection;
}>;


export type KingPassHoldersQuery = { users: Array<Pick<User, 'id' | 'lockedAmountForKingpass' | 'kingpassActiveUntil'>>, _meta?: Maybe<{ block: Pick<_Block_, 'number'> }> };

export type KingDayDataQueryVariables = Exact<{
  first: Scalars['Int'];
}>;


export type KingDayDataQuery = { kingDayDatas: Array<Pick<KingDayData, 'amountClaimed' | 'amountBurned' | 'amountStaked' | 'supply' | 'date' | 'txCount' | 'holders'>>, _meta?: Maybe<{ block: Pick<_Block_, 'number'> }> };

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
  user?: InputMaybe<Scalars['Bytes']>;
}>;


export type UserQuery = { user?: Maybe<Pick<User, 'id' | 'walletAmount' | 'stakedAmount' | 'totalAmount' | 'lockedUntil' | 'lockedAmountForKingpass' | 'kingpassActiveUntil'>>, nftholder?: Maybe<{ nfts: Array<Pick<KingNFT, 'tokenID'>> }>, transfers: Array<Pick<Transfer, 'blockNumber' | 'blockTimestamp' | 'from' | 'id' | 'to' | 'transactionHash' | 'value'>>, deposits: Array<Pick<Deposit, 'from' | 'blockTimestamp' | 'blockNumber' | 'id' | 'transactionHash' | 'value'>>, withdraws: Array<Pick<Withdraw, 'to' | 'blockTimestamp' | 'blockNumber' | 'id' | 'transactionHash' | 'value'>>, compounds: Array<Pick<Compound, 'to' | 'blockTimestamp' | 'blockNumber' | 'id' | 'transactionHash' | 'value'>>, claimRewards: Array<Pick<ClaimReward, 'to' | 'blockTimestamp' | 'blockNumber' | 'id' | 'transactionHash' | 'value'>>, _meta?: Maybe<{ block: Pick<_Block_, 'number'> }> };

export type UsersQueryVariables = Exact<{
  first: Scalars['Int'];
  orderBy?: InputMaybe<User_orderBy>;
  orderDirection: OrderDirection;
  walletsToExclude?: InputMaybe<Array<Scalars['Bytes']> | Scalars['Bytes']>;
}>;


export type UsersQuery = { users: Array<Pick<User, 'id' | 'walletAmount' | 'stakedAmount' | 'totalAmount' | 'lockedUntil' | 'lockedAmountForKingpass' | 'kingpassActiveUntil'>>, _meta?: Maybe<{ block: Pick<_Block_, 'number'> }> };


export const KingPassHoldersDocument = gql`
    query KingPassHolders($first: Int!, $orderDirection: OrderDirection!) {
  users(
    first: $first
    orderBy: kingpassActiveUntil
    orderDirection: $orderDirection
    where: {or: [{lockedAmountForKingpass_gt: "0"}, {kingpassActiveUntil_gt: "0"}]}
  ) {
    id
    lockedAmountForKingpass
    kingpassActiveUntil
  }
  _meta {
    block {
      number
    }
  }
}
    ` as unknown as DocumentNode<KingPassHoldersQuery, KingPassHoldersQueryVariables>;
export const KingDayDataDocument = gql`
    query KingDayData($first: Int!) {
  kingDayDatas(first: $first, orderBy: date, orderDirection: desc) {
    amountClaimed
    amountBurned
    amountStaked
    supply
    date
    txCount
    holders
  }
  _meta {
    block {
      number
    }
  }
}
    ` as unknown as DocumentNode<KingDayDataQuery, KingDayDataQueryVariables>;
export const UserDocument = gql`
    query User($id: ID!, $user: Bytes) {
  user(id: $id) {
    id
    walletAmount
    stakedAmount
    totalAmount
    lockedUntil
    lockedAmountForKingpass
    kingpassActiveUntil
  }
  nftholder(id: $id) {
    nfts(first: 5000) {
      tokenID
    }
  }
  transfers(
    where: {or: [{from: $user}, {to: $user}]}
    orderBy: blockTimestamp
    orderDirection: desc
  ) {
    blockNumber
    blockTimestamp
    from
    id
    to
    transactionHash
    value
  }
  deposits(where: {from: $user}, orderBy: blockTimestamp, orderDirection: desc) {
    from
    blockTimestamp
    blockNumber
    id
    transactionHash
    value
  }
  withdraws(where: {to: $user}, orderBy: blockTimestamp, orderDirection: desc) {
    to
    blockTimestamp
    blockNumber
    id
    transactionHash
    value
  }
  compounds(where: {to: $user}, orderBy: blockTimestamp, orderDirection: desc) {
    to
    blockTimestamp
    blockNumber
    id
    transactionHash
    value
  }
  claimRewards(where: {to: $user}, orderBy: blockTimestamp, orderDirection: desc) {
    to
    blockTimestamp
    blockNumber
    id
    transactionHash
    value
  }
  _meta {
    block {
      number
    }
  }
}
    ` as unknown as DocumentNode<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query Users($first: Int!, $orderBy: User_orderBy, $orderDirection: OrderDirection!, $walletsToExclude: [Bytes!]) {
  users(
    first: $first
    orderBy: $orderBy
    orderDirection: $orderDirection
    where: {id_not_in: $walletsToExclude}
  ) {
    id
    walletAmount
    stakedAmount
    totalAmount
    lockedUntil
    lockedAmountForKingpass
    kingpassActiveUntil
  }
  _meta {
    block {
      number
    }
  }
}
    ` as unknown as DocumentNode<UsersQuery, UsersQueryVariables>;





export type Requester<C = {}, E = unknown> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    KingPassHolders(variables: KingPassHoldersQueryVariables, options?: C): Promise<KingPassHoldersQuery> {
      return requester<KingPassHoldersQuery, KingPassHoldersQueryVariables>(KingPassHoldersDocument, variables, options) as Promise<KingPassHoldersQuery>;
    },
    KingDayData(variables: KingDayDataQueryVariables, options?: C): Promise<KingDayDataQuery> {
      return requester<KingDayDataQuery, KingDayDataQueryVariables>(KingDayDataDocument, variables, options) as Promise<KingDayDataQuery>;
    },
    User(variables: UserQueryVariables, options?: C): Promise<UserQuery> {
      return requester<UserQuery, UserQueryVariables>(UserDocument, variables, options) as Promise<UserQuery>;
    },
    Users(variables: UsersQueryVariables, options?: C): Promise<UsersQuery> {
      return requester<UsersQuery, UsersQueryVariables>(UsersDocument, variables, options) as Promise<UsersQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;