import { IRawTransaction, ITransaction, TRANSACTION_TYPE } from '@/types';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';

const BIGDECIMAL_9 = new BigNumber('1000000000');

export const formatBigIntToDecimal = (value: number | string): string => {
  return numberToLocaleString(fromBaseUnit(value));
};

export const fromBaseUnit = (value: number | string): number => {
  const tempBN = new BigNumber(value);
  return tempBN.dividedBy(BIGDECIMAL_9).toNumber();
};

export const numberToLocaleString = (value: number, maxDigits = 2): string => {
  return value.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDigits,
  });
};

export const formatToDollarString = (value: number): string => {
  return `$${numberToLocaleString(value)}`;
};

export const displayLockedUntil = (timestamp: number): string => {
  const currentTimestamp = dayjs().unix();
  const lockedUntil = dayjs.unix(timestamp).unix();
  return lockedUntil < currentTimestamp
    ? 'Not locked anymore'
    : dayjs.unix(timestamp).toString();
};

export const addressToLink = (address: string): string => {
  return `https://bscscan.com/token/${process.env.VUE_APP_TOKEN_ADDRESS}?a=${address}`;
};

export function shortHexString(address: string, length = 6): string {
  if (!address) return '';
  const addressLength = address.length;

  const firstPart = address.substring(0, length + 2);
  const lastPart = address.substring(addressLength - length, addressLength);
  return `${firstPart}...${lastPart}`;
}

export const formatTransactionData = (
  data: IRawTransaction,
  type: TRANSACTION_TYPE
): ITransaction => {
  return {
    id: data.id,
    from: data.from ?? null,
    amount: parseInt(data.value),
    type: type,
    timestamp: parseInt(data.blockTimestamp),
    blockNumber: parseInt(data.blockNumber),
    txHash: data.transactionHash,
    to: data.to ?? null,
  };
};
