
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';
import NavigationDrawer from '@/components/common/NavigationDrawer.vue';
import HeaderBar from '@/components/common/HeaderBar.vue';

export default defineComponent({
  name: 'AppEntry',
  components: { HeaderBar, NavigationDrawer },
  computed: {
    ...mapState('settings', {
      theme: 'theme',
    }),
  },
  methods: {
    ...mapActions('global', ['fetchUsdPrices']),
  },
  mounted() {
    this.fetchUsdPrices();
  },
});
