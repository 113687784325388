import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createBlock(_component_v_navigation_drawer, {
    modelValue: _ctx.displayNavigation,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.displayNavigation) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_list, {
        density: "compact",
        nav: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list_item, {
            title: "Dashboard",
            link: "",
            to: "/"
          }),
          _createVNode(_component_v_list_item, {
            title: "Holders",
            link: "",
            to: "/holders"
          }),
          _createVNode(_component_v_list_item, {
            title: "King Pass Holders",
            link: "",
            to: "/kingpassholders"
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}