
import { ExecutionResult } from 'graphql';
import { defineComponent } from 'vue';
import { fromBaseUnit } from '@/utils/format';
import { KingDayDataDocument, KingDayDataQuery, execute } from '~graphql';
import { mapActions, mapState } from 'vuex';
import StatsCard from './StatsCard.vue';
import StakingChart from './statsCharts/StakingChart.vue';
import StakingTVLChart from './statsCharts/StakingTVLChart.vue';
/* import HoldersChart from './StatsCharts/HoldersChart.vue'; */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
declare interface Data {
  amountStaked: number;
  amountBurned: number;
  amountClaimed: number;
  supply: number;
  loadingStats: boolean;
  tvlData: Array<number>;
  stakingsData: Array<number>;
  holdersData: Array<number>;
  daysData: Array<number>;
}

export default defineComponent({
  name: 'StatsOverview',
  components: {
    StatsCard,
    StakingChart,
    StakingTVLChart,
    /*     HoldersChart, */
  },
  data() {
    return {
      amountWallet: 0,
      amountStaked: 0,
      amountBurned: 0,
      amountClaimed: 0,
      supply: 0,
      loadingStats: false,
      tvlData: [],
      stakingsData: [],
      daysData: [],
      holdersData: [],
    } as Data;
  },
  computed: {
    ...mapState('global', {
      usdPrices: 'usdPrices',
      isLoadingUsdPrices: 'isLoadingUsdPrices',
    }),
    ...mapState('settings', {
      theme: 'theme',
    }),
    isLoading() {
      return this.isLoadingUsdPrices || this.loadingStats;
    },
    unstakedAmount() {
      return this.supply - this.amountStaked;
    },
    stakedPercentage() {
      return (this.amountStaked / this.supply) * 100;
    },
    queryVariables() {
      return {
        first: 30,
      };
    },
  },
  methods: {
    ...mapActions('global', ['updateBlockNumber']),
    queryStats() {
      if (this.isLoadingUsdPrices || this.usdPrices.size === 0) return;
      this.loadingStats = true;
      // 30 days
      execute(KingDayDataDocument, { first: 30 })
        .then((result: ExecutionResult<KingDayDataQuery>) => {
          const tvlData = [] as Array<number>;
          const daysData = [] as Array<number>;
          const stakingsData = [] as Array<number>;
          const holdersData = [] as Array<number>;
          result.data?.kingDayDatas.forEach((dayData) => {
            const blockNumber = result.data?._meta?.block.number;
            this.updateBlockNumber(blockNumber);
            const day = dayData.date * 1000;
            const holderData = dayData.holders;
            const stakingData = fromBaseUnit(dayData.amountStaked);
            const tvl =
              stakingData * (this.usdPrices.get(dayData.date * 1000) ?? 0);

            holdersData.push(holderData);
            stakingsData.push(stakingData);
            daysData.push(day);
            tvlData.push(tvl);
          });

          const currentDay = result.data?.kingDayDatas[0];
          if (currentDay) {
            const { amountStaked, amountBurned, amountClaimed, supply } =
              currentDay;
            this.amountStaked = fromBaseUnit(amountStaked);
            this.amountBurned = fromBaseUnit(amountBurned);
            this.amountClaimed = fromBaseUnit(amountClaimed);
            this.supply = fromBaseUnit(supply);
          }

          this.holdersData = holdersData;
          this.stakingsData = stakingsData;
          this.tvlData = tvlData;
          this.daysData = daysData;
        })
        .finally(() => {
          this.loadingStats = false;
        });
    },
  },
  mounted() {
    this.queryStats();
  },
  watch: {
    isLoadingUsdPrices() {
      this.queryStats();
    },
  },
});
