
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';
export default defineComponent({
  name: 'NavigationDrawer',
  computed: {
    ...mapState('global', {
      showNavigation: 'showNavigation',
    }),
    displayNavigation: {
      get: function () {
        return this.showNavigation;
      },
      set: function (value: boolean) {
        this.setNavigationDrawer(value);
      },
    },
  },
  methods: {
    ...mapActions('global', ['setNavigationDrawer']),
  },
});
