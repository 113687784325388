import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatsCard = _resolveComponent("StatsCard")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_StakingTVLChart = _resolveComponent("StakingTVLChart")!
  const _component_StakingChart = _resolveComponent("StakingChart")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_StatsCard, {
                loading: _ctx.isLoading,
                title: `Unstaked amount`,
                label: `${_ctx.$filters.numberToLocaleString(
            _ctx.unstakedAmount
          )} KING`
              }, null, 8, ["loading", "label"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_StatsCard, {
                loading: _ctx.isLoading,
                title: `Staked amount`,
                label: `${_ctx.$filters.numberToLocaleString(
            _ctx.amountStaked
          )} KING`
              }, null, 8, ["loading", "label"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_StatsCard, {
                loading: _ctx.isLoading,
                title: `Percentage staked`,
                label: `${_ctx.$filters.numberToLocaleString(
            _ctx.stakedPercentage
          )} %`
              }, null, 8, ["loading", "label"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_StatsCard, {
                loading: _ctx.isLoading,
                title: `Total supply`,
                label: `${_ctx.$filters.numberToLocaleString(_ctx.supply)} KING`
              }, null, 8, ["loading", "label"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_StatsCard, {
                loading: _ctx.isLoading,
                title: `Burned`,
                label: `${_ctx.$filters.numberToLocaleString(
            _ctx.amountBurned
          )} KING`
              }, null, 8, ["loading", "label"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_StatsCard, {
                loading: _ctx.isLoading,
                title: `Claimed`,
                label: `${_ctx.$filters.numberToLocaleString(_ctx.amountClaimed)} KING`
              }, null, 8, ["loading", "label"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_StakingTVLChart, {
                tvlData: _ctx.tvlData,
                daysData: _ctx.daysData
              }, null, 8, ["tvlData", "daysData"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_StakingChart, {
                stakingsData: _ctx.stakingsData,
                daysData: _ctx.daysData
              }, null, 8, ["stakingsData", "daysData"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}